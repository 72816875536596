// ForJWT Auth
import {getUserFromJwtAuth} from '@mj/helpers/AuthHelper';
import {useJWTAuth, useJWTAuthActions} from '@mj/services/auth/JWTAuthProvider';

export const useAuthUser = () => {
  const {user, isAuthenticated, isLoading, branches} = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
    branches,
  };
};

export const useAuthMethod = () => {
  const {signInUser, logout, branches, setBranches} = useJWTAuthActions();
  return {
    signInUser,
    logout,
    branches,
    setBranches,
  };
};
