import React from 'react';
import {MdOutlineTextsms, MdOutlineWhatsapp, MdTaskAlt} from 'react-icons/md';
import {
  BsBank,
  BsCashCoin,
  BsCurrencyDollar,
  BsShieldLock,
} from 'react-icons/bs';
import {RoutePermittedRole} from '@mj/constants/AppEnums';
import {FaDonate, FaMapMarkedAlt} from 'react-icons/fa';
import {
  GiOrganigram,
  GiPayMoney,
  GiTakeMyMoney,
  GiWaterBottle,
} from 'react-icons/gi';
import {
  AiOutlineDashboard,
  AiOutlineReconciliation,
  AiOutlineUsergroupAdd,
} from 'react-icons/ai';
import {TbTargetArrow} from 'react-icons/tb';
import {BiCategory, BiChat} from 'react-icons/bi';
import {FcStart} from 'react-icons/fc';
const routesConfig = [
  {
    id: 'dashboards',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'admin',
        title: 'admin',
        permittedRole: RoutePermittedRole.adminPanel,
        messageId: 'sidebar.dashboard',
        icon: <AiOutlineDashboard />,
        path: '/dashboards/admin',
      },
      {
        id: 'donation',
        permittedRole: RoutePermittedRole.donation,
        title: 'donation',
        messageId: 'sidebar.apps.donation',
        path: '/donation',
        icon: <FaDonate />,
      },
      {
        id: 'executor',
        permittedRole: RoutePermittedRole.executor,
        title: 'executor',
        messageId: 'sidebar.apps.execution',
        path: '/executor',
        icon: <MdTaskAlt />,
      },
      {
        id: 'donor',
        permittedRole: RoutePermittedRole.donor,
        title: 'donor',
        messageId: 'sidebar.apps.donor',
        path: '/donor',
        icon: <GiPayMoney />,
      },
      {
        id: 'target',
        permittedRole: RoutePermittedRole.target,
        title: 'target',
        messageId: 'sidebar.apps.target',
        path: '/target',
        icon: <TbTargetArrow />,
      },
      {
        id: ' transactions',
        permittedRole: RoutePermittedRole.transactions,
        title: 'transactions',
        messageId: 'sidebar.apps.transactions',
        icon: <GiTakeMyMoney />,
        path: 'transactions',
        type: 'collapse',
        children: [
          {
            id: 'bank',
            permittedRole: RoutePermittedRole.bank,
            title: 'bank',
            messageId: 'sidebar.apps.bank',
            path: '/transactions/bank',
            icon: <BsBank />,
          },
          {
            id: 'cash',
            permittedRole: RoutePermittedRole.cash,
            title: 'cash',
            messageId: 'sidebar.apps.cash',
            path: '/transactions/cash',
            icon: <BsCashCoin />,
          },
        ],
      },
      {
        id: 'reconcile',
        permittedRole: RoutePermittedRole.reconcile,
        title: 'reconcile',
        messageId: 'sidebar.apps.reconcile',
        path: '/reconcile',
        icon: <AiOutlineReconciliation />,
      },
      {
        id: 'waterwells',
        permittedRole: RoutePermittedRole.waterwell,
        title: 'waterWell',
        messageId: 'sidebar.apps.waterwell',
        path: '/waterwells',
        icon: <GiWaterBottle />,
      },
      {
        id: 'fond',
        permittedRole: RoutePermittedRole.fondList,
        title: 'fondList',
        messageId: 'sidebar.apps.fondList',
        path: '/fond',
        icon: <BiCategory />,
      },
      {
        id: 'executorlist',
        permittedRole: RoutePermittedRole.executorlist,
        title: 'executorlist',
        messageId: 'sidebar.apps.executorlist',
        path: '/executorlist',
        icon: <FcStart />,
      },
      {
        id: 'places',
        permittedRole: RoutePermittedRole.placeList,
        title: 'PlaceList',
        messageId: 'sidebar.apps.placeList',
        path: '/places',
        icon: <FaMapMarkedAlt />,
      },
      {
        id: 'currency',
        permittedRole: RoutePermittedRole.currency,
        title: 'currency',
        messageId: 'sidebar.apps.currency',
        path: '/currency',
        icon: <BsCurrencyDollar />,
      },
      {
        id: 'SMS',
        title: 'SMS',
        messageId: 'sidebar.SMS',
        permittedRole: RoutePermittedRole.SMS,
        icon: <MdOutlineTextsms />,
        path: '/app/SMS',
      },
      {
        id: 'chat',
        permittedRole: RoutePermittedRole.chat,
        title: 'chat',
        messageId: 'sidebar.apps.chat',
        path: '/chat',
        icon: <BiChat />,
      },
      {
        id: 'whatsapp',
        title: 'whatsapp',
        messageId: 'sidebar.whatsapp',
        permittedRole: RoutePermittedRole.whatsapp,
        icon: <MdOutlineWhatsapp />,
        type: 'collapse',
        children: [
          {
            id: 'template',
            title: 'template',
            messageId: 'sidebar.template',
            permittedRole: RoutePermittedRole.whatsapp,
            path: '/apps/whatsapp/template',
          },
          {
            id: 'messages',
            title: 'messages',
            messageId: 'sidebar.messages',
            permittedRole: RoutePermittedRole.whatsapp,
            path: '/apps/whatsapp/messages',
          },
        ],
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    messageId: 'sidebar.settings',
    permittedRole: RoutePermittedRole.employee,
    type: 'group',
    children: [
      {
        id: 'permission',
        key: 130,
        permittedRole: RoutePermittedRole.permissions,
        title: 'Permission',
        messageId: 'sidebar.settings.premission',
        path: '/settings/permission',
        icon: <BsShieldLock />,
      },
      {
        id: 'company',
        key: 110,
        permittedRole: RoutePermittedRole.branch,
        title: 'branches',
        messageId: 'sidebar.settings.branch',
        path: '/settings/company',
        icon: <GiOrganigram />,
      },
      {
        id: 'user',
        permittedRole: RoutePermittedRole.employee,
        title: 'employee',
        messageId: 'sidebar.settings.users',
        icon: <AiOutlineUsergroupAdd />,
        path: '/settings/user',
        power: [4],
      },
    ],
  },
];
export default routesConfig;
